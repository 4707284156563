export const popupVideo = {
  type: "nested",
  label: "Popup Video",
  fields: {
    show: {
      type: "boolean",
      label: "Show"
    },
    videoId: {
      type: "text",
      label: "Youtube Video ID"
    },
    playButtonText: {
      type: "text",
      label: "Play Button Text"
    },
    footerText: {
      type: "text",
      label: "Popup Footer Text"
    }
  }
};
