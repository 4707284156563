import { PureComponent } from "react";
import { componentStyles } from "./remote-work-header-component.scss";
import BaseHeaderComponent from "segments/desktop/header/layouts/base-header/base-header-component";

export default class RemoteWorkHeaderComponent extends PureComponent {
  render() {
    return (
      <div className="remote-work-header-component">
        <BaseHeaderComponent />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RemoteWorkHeaderComponent.defaultProps = {};
