import { PureComponent } from "react";
import { componentStyles } from "./dynamic-header-component.scss";
import { ROOT_LINK, MONDAY_LOGO_SRC } from "constants/links";
import DynamicHeaderDesktopComponent from "./components/dynamic-header-desktop/dynamic-header-desktop-component";
import DynamicHeaderMobileComponent from "./components/dynamic-header-mobile/dynamic-header-mobile-component";

export default class DynamicHeaderComponent extends PureComponent {
  render() {
    return (
      <div className="dynamic-header-component">
        <div className="desktop-wrapper">
          <DynamicHeaderDesktopComponent {...this.props} />
        </div>

        <div className="mobile-wrapper">
          <DynamicHeaderMobileComponent {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

DynamicHeaderComponent.defaultProps = {
  logoImage: {
    src: MONDAY_LOGO_SRC,
    href: ROOT_LINK
  },
  linkItems: []
};
