import LongFooter from "segments/desktop/core-components/long-footer/long-footer";
import SlimFooter from "segments/desktop/core-components/slim-footer/slim-footer.js";
import { SLIM, LONG } from "./footer-consts";
import DefaultProps from "./default-props";
import { componentStyles } from "./base-footer-component.scss";

const BaseFooterComponent = (props) => {
  const Footer =
    {
      [LONG]: LongFooter,
      [SLIM]: SlimFooter
    }[props.footer_type] || SlimFooter;

  return (
    <div className="base-footer-component">
      <Footer {...props} />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

BaseFooterComponent.defaultProps = DefaultProps;

export default BaseFooterComponent;
