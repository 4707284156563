import { PureComponent } from "react";
import SlimFooter from "segments/desktop/core-components/slim-footer/slim-footer.js";

import { componentStyles } from "./slim-footer-component.scss";

export default class SlimFooterComponent extends PureComponent {
  render() {
    return (
      <div className="slim-footer-component">
        <SlimFooter {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SlimFooterComponent.defaultProps = {};
