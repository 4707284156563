import { FIELD_TYPES } from "constants/template-field-type";
import {
  REGULAR_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
  SIGNUP_BUTTON_TYPE,
  YOUTUBE_POPUP_LINK_TYPE
} from "segments/desktop/core-components/button/button-consts";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import YouTubePopupButtonConfig from "segments/desktop/core-components/button/youtube-popup-button/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

import { ELEVATE_THEME_ID } from "segments/segments-service";
import { ELEVATE_BUTTON_COLLOR_NAME } from "segments/button-service";

const ExtendedRegularButtonFieldsConfig = {
  ...RegularButtonFieldsConfig,
  color: {
    ...RegularButtonFieldsConfig.color,
    possibleColors: [...RegularButtonFieldsConfig.color.possibleColors, ELEVATE_BUTTON_COLLOR_NAME]
  }
};

export default {
  bannerText: {
    type: FIELD_TYPES.TEXT,
    label: "Text",
    translatable: true
  },
  bannerCta: {
    type: FIELD_TYPES.TEXT,
    label: "Button Text",
    translatable: true
  },
  theme: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Theme"
  },
  buttonConfig: {
    type: FIELD_TYPES.CONDITIONAL_NESTED,
    label: "Button",
    conditionalKeyName: "buttonType",
    options: [
      { text: REGULAR_BUTTON_TYPE, fieldsConfig: ExtendedRegularButtonFieldsConfig },
      { text: SECONDARY_BUTTON_TYPE, fieldsConfig: SecondaryButtonFieldsConfig },
      { text: SIGNUP_BUTTON_TYPE, fieldsConfig: SignupButtonFieldsConfig },
      { text: YOUTUBE_POPUP_LINK_TYPE, fieldsConfig: YouTubePopupButtonConfig }
    ]
  }
};
