import css from "styled-jsx/css";
import colors from "styles/colors";

export const componentStyles = css`
  .new-joiners-footer-social-link-item-component {
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: ${colors["black"]};

    .social-icon-wrapper {
      margin-right: 8px;

      .social-icon {
        transition: fill 150ms ease;
      }
    }

    .social-label {
      transform: translateY(-2px);
    }
  }
`;
