import { FIELD_TYPES } from "constants/template-field-type";
import { HEADER_POPUP_OPTIONS } from "constants/header-popup";

import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    logoPopup: {
      type: FIELD_TYPES.COMBO,
      label: "Logo Popup",
      options: HEADER_POPUP_OPTIONS
    },
    mobileStickySignup: { type: FIELD_TYPES.BOOLEAN, label: "Mobile sticky signup (In test don't touch)" },
    shouldAddProductLearnMoreButtons: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show header's product learn more buttons (In test don't touch)"
    },
    mobileDarkHeader: { type: FIELD_TYPES.BOOLEAN, label: "Mobile dark header (In test don't touch)" },
    isExistingAccountHeader: { type: FIELD_TYPES.BOOLEAN, label: "Use existing account header (welcome back page)" },
    showSuccessStoriesInHeader: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show success stories in header (In test don't touch)"
    }
  }
};
