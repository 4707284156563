export const collaborationWith = {
  type: "nested",
  label: "Collboration Config",
  fields: {
    show: {
      type: "boolean",
      label: "Show"
    },
    color: {
      type: "colorPicker",
      label: "Text Color",
      possibleColors: ["black", "white"]
    },
    text: {
      type: "text",
      label: "Text"
    },
    imageUrl: {
      type: "gallery",
      label: "Select a logo",
      defaultImagesKeys: ["static/img/templates/collaboration-with/podcast-logos/"],
      options: [{ value: "/static/img/podcast-logos/codepen.png", text: "Codepen" }]
    }
  }
};
