export const coupon = {
  type: "nested",
  label: "Coupon",
  fields: {
    code: {
      type: "text",
      label: "Code"
    },
    daysToExpire: {
      type: "text",
      label: "Expire Days"
    }
  }
};
