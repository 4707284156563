import { FIELD_TYPES } from "constants/template-field-type";

export const stars = {
  type: FIELD_TYPES.NESTED,
  label: "Stars",
  fields: {
    paddingTop: { type: FIELD_TYPES.TEXT, label: "Padding top" },
    showStars: { type: FIELD_TYPES.BOOLEAN, label: "Show stars" },
    numberOfSelectedStars: { type: FIELD_TYPES.TEXT, label: "Number of selected stars" }
  }
};
