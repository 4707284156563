import { FIELD_TYPES } from "constants/template-field-type";

export const circle = {
  type: FIELD_TYPES.NESTED,
  label: "Circle",
  fields: {
    position: { type: "text", label: "position" },
    width: { type: "text", label: "Width" },
    height: { type: "text", label: "Height" },
    background: { type: "text", label: "background" },
    top: { type: "text", label: "top" },
    left: { type: "text", label: "left" },
    zIndex: { type: "text", label: "z index" },
    borderRadius: { type: "text", label: "border radius" }
  }
};
