import { PureComponent } from "react";
import Banner from "segments/desktop/core-components/banner/banner";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { componentStyles } from "./with-background-banner-component.scss";

export default class WithBackgroundBannerComponent extends PureComponent {
  render() {
    return (
      <div className="with-background-banner-component">
        <Banner {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

WithBackgroundBannerComponent.defaultProps = {
  ...Banner.defaultProps,
  bannerleftImage: "/static/img/banner/banner-left-image3.png",
  bannerRightImage: "/static/img/banner/banner-right-image3.png",
  buttonConfig: { buttonType: SIGNUP_BUTTON_TYPE }
};
