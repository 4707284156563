import { PureComponent } from "react";
import classnames from "classnames";
import BasicHeaderComponent from "segments/desktop/header/layouts/components/basic-header/basic-header-component";
import HamburgerMenuComponent from "components/core/top-bar-new/hamburger-menu/hamburger-menu-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import { componentStyles } from "./dynamic-header-mobile-component.scss";
import DynamicMobileHeaderMenuComponent from "./dynamic-mobile-header-menu/dynamic-mobile-header-menu-component";

class DynamicHeaderMobileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false
    };
  }

  toogleOpenMenu = (isMenuOpen) => {
    if (isMenuOpen) {
      this._basicHeader.forceShadow();
    } else {
      this._basicHeader.unforceShadow();
    }

    this.setState({ isMenuOpen });
  };

  getMobileLinkItems = () => {
    const { linkItems } = this.props;
    return linkItems.filter((linkItem) => !linkItem.hideInMobile);
  };

  render() {
    const { showMenu, logoImage, translate } = this.props;
    const { src, href } = logoImage;
    const { isMenuOpen } = this.state;

    const mobileLinkItems = this.getMobileLinkItems();

    return (
      <header className="dynamic-header-mobile-component">
        <BasicHeaderComponent
          ref={(element) => (this._basicHeader = element)}
          showMenu={showMenu}
          imageSrc={src}
          imageHref={href}
          isMenuOpen={isMenuOpen}
        >
          <div className="mobile-header-content" aria-label={translate("header.mainMenu")} role="navigation">
            <HamburgerMenuComponent changeStateCallback={this.toogleOpenMenu} isOpen={isMenuOpen} />
          </div>
        </BasicHeaderComponent>

        <div className={classnames("header-menu-component-wrapper", { "is-open": isMenuOpen })}>
          <DynamicMobileHeaderMenuComponent
            ref={(element) => (this._headerMenu = element)}
            linkItems={mobileLinkItems}
          />
        </div>

        <style jsx>{componentStyles}</style>
      </header>
    );
  }
}

export default withGenericConfig(DynamicHeaderMobileComponent);
