import { FIELD_TYPES } from "constants/template-field-type";

export const signupForm = {
  type: FIELD_TYPES.NESTED,
  label: "Signup Config",
  fields: {
    buttonColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Button Color",
      possibleColors: ["space-blue", "dodger-blue", "blue", "iris", "white"]
    },
    buttonText: {
      type: FIELD_TYPES.TEXT,
      label: "Button Text"
    },
    inputPlaceholder: {
      type: FIELD_TYPES.TEXT,
      label: "Input Placeholder"
    },
    showDownloadButtonOnMobile: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show download button on mobile"
    },
    downloadButtonTitle: {
      type: FIELD_TYPES.TEXT,
      label: "Download button title"
    },
    placement: {
      type: FIELD_TYPES.TEXT,
      label: "BigBrain Event Placement"
    },
    focusOnLoad: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Auto Focus"
    }
  }
};

const signupFormWithDecorationsConfig = signupForm;
signupFormWithDecorationsConfig.fields.showDecorations = {
  type: FIELD_TYPES.BOOLEAN,
  label: "Show decorations"
};
export const signupFormWithDecorations = signupFormWithDecorationsConfig;
