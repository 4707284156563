import css from "styled-jsx/css";

export const componentStyles = css`
  .dynamic-header-component {
    .desktop-wrapper {
      display: block;
    }

    .mobile-wrapper {
      display: none;
    }

    @media (max-width: 1090px) {
      .desktop-wrapper {
        display: none;
      }

      .mobile-wrapper {
        display: block;
      }
    }
  }
`;
