import { GRADIANT_BRAND_GRAY_COLOR_NAME, WORKFORMS_RED_COLOR_NAME } from "styles/color-consts";

const {
  WHITE_COLOR_NAME,
  RIVERSTONE_COLOR_NAME,
  PROJECTS_ORANGE_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  LIGHT_PROJECTS_ORANGE_COLOR_NAME,
  LIGHT_MARKETING_RED_COLOR_NAME,
  LIGHT_CRM_GREEN_COLOR_NAME,
  LIGHT_DEV_GREEN_COLOR_NAME,
  LIGHT_WORK_OS_IRIS_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  DARK_WORK_OS_IRIS_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  SIGN_UP_WITH_PRODUCTS_COLOR_NAME,
  BACKGROUND_GRAY_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  DODGER_BLUE_COLOR_NAME,
  BLUE_COLOR_NAME,
  SPACE_BLUE_GRADIANT_COLOR_NAME,
  SPACE_BLUE_VIDEO_ONLY_COLOR_NAME,
  SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME,
  ELEVATE_BANNER_GRADIANT_COLOR_NAME,
  ELEVATE_BUTTON_GRADIANT_COLOR_NAME,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
  BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
  BRAND_PRIMARY_YELLOW,
  BRAND_YELLOW_DARK_TINT_01,
  BRAND_YELLOW_DARKER_TINT_02,
  BRAND_PRIMARY_RED,
  BRAND_RED_DARK_TINT_01,
  BRAND_RED_DARKER_TINT_02,
  BRAND_PRIMARY_GREEN,
  BRAND_GREEN_DARK_TINT_01,
  BRAND_GREEN_DARKER_TINT_02,
  BRAND_DARK_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  CRM_PRIMARY_COLOR,
  CRM_LIGHTER_TINT_01,
  CRM_LIGHT_TINT_02,
  CRM_DARK_TINT_01,
  CRM_DARKER_TINT_02,
  DEV_PRIMARY_COLOR,
  DEV_LIGHTER_TINT_01,
  DEV_LIGHT_TINT_02,
  DEV_DARK_TINT_01,
  DEV_DARKER_TINT_02,
  MARKETER_PRIMARY_COLOR,
  MARKETER_LIGHTER_TINT_01,
  MARKETER_LIGHT_TINT_02,
  MARKETER_DARK_TINT_01,
  MARKETER_DARKER_TINT_02,
  PROJECTS_PRIMARY_COLOR,
  PROJECTS_LIGHTER_TINT_01,
  PROJECTS_LIGHT_TINT_02,
  PROJECTS_DARK_TINT_01,
  PROJECTS_DARKER_TINT_02,
  PROJECTS_DARKEST_TINT_03
} = require("../styles/color-consts");

const colorOptions = [
  { value: "brand-red", label: "Red" },
  { value: "brand-enterprise-dark", label: "Dark Enterprise" },
  { value: "brand-green", label: "Green" },
  { value: "brand-purple", label: "Purple" },
  { value: "brand-pink", label: "Pink" },
  { value: "brand-blue", label: "Blue" },
  { value: "brand-iris", label: "Iris" },
  { value: "brand-light-blue", label: "Light Blue" },
  { value: "brand-dark-blue", label: "Dark Blue" },
  { value: "transparent", label: "Transparent" },
  { value: "snow", label: "Snow" },
  { value: "purple", label: "Purple" },
  { value: "iris", label: "Iris" },
  { value: "royal", label: "Royal" },
  { value: "green", label: "Green" },
  { value: "yellow", label: "Yellow" },
  { value: RIVERSTONE_COLOR_NAME, label: "Riverstone" },
  { value: "pink", label: "Pink" },
  { value: BLUE_COLOR_NAME, label: "Blue" },
  { value: "enterprise", label: "Enterprise Blue" },
  { value: "gradiant-gray", label: "Gradiant Gray" },
  { value: GRADIANT_BRAND_GRAY_COLOR_NAME, label: "Gradiant Brand Gray (new)" },
  { value: "gradiant-pink", label: "Gradiant Pink" },
  { value: DODGER_BLUE_COLOR_NAME, label: "Dodger Blue" },
  { value: "light-blue", label: "Light Blue" },
  { value: "green-2", label: "Green" },
  { value: "dark-green", label: "Grass Green" },
  { value: "bright-green", label: "Bright Green" },
  { value: "saladish", label: "Saladish" },
  { value: "dark-orange", label: "Dark Orange" },
  { value: "brand-yellow", label: "Egg Yolk" },
  { value: "red", label: "Dark Red" },
  { value: "sofia-pink", label: "Sofia Pink" },
  { value: "lipstick", label: "Lipstick" },
  { value: "dark-purple", label: "Dark purple" },
  { value: "purple-v2", label: "Purple" },
  { value: "orange", label: "Orange" },
  { value: "dark-blue-v2", label: "Dark Blue" },
  { value: "light-blue-v2", label: "Light Blue" },
  { value: "chill-blue", label: "Chill Blue" },
  { value: "silver", label: "Explosive" },
  { value: "brand-gray", label: "American Gray" },
  { value: WHITE_COLOR_NAME, label: "White" },
  { value: "brown", label: "Brown" },
  { value: "sunset", label: "Sunset" },
  { value: "bubble", label: "Bubble" },
  { value: "peach", label: "Peach" },
  { value: "berry", label: "Berry" },
  { value: "winter", label: "Winter" },
  { value: "river", label: "River" },
  { value: "navy", label: "Navy" },
  { value: "aquamarine", label: "Aquamarine" },
  { value: "indigo", label: "Indigo" },
  { value: "dark-indigo", label: "Dark Indigo" },
  { value: MARKETING_RED_COLOR_NAME, label: "Marketing red" },
  { value: CRM_GREEN_COLOR_NAME, label: "CRM green" },
  { value: PROJECTS_ORANGE_COLOR_NAME, label: "Projects orange" },
  { value: DEV_GREEN_COLOR_NAME, label: "Software green" },
  { value: WORK_OS_IRIS_COLOR_NAME, label: "Brand & WM Primary Color" },
  { value: WORKFORMS_RED_COLOR_NAME, label: "Workforms red" },
  { value: LIGHT_MARKETING_RED_COLOR_NAME, label: "Marketing red - light" },
  { value: LIGHT_CRM_GREEN_COLOR_NAME, label: "CRM green - light" },
  { value: LIGHT_PROJECTS_ORANGE_COLOR_NAME, label: "Projects orange - light" },
  { value: LIGHT_DEV_GREEN_COLOR_NAME, label: "Software green - light" },
  { value: LIGHT_WORK_OS_IRIS_COLOR_NAME, label: "Work OS Iris - light" },
  { value: DARK_MARKETING_RED_COLOR_NAME, label: "Marketing red - dark" },
  { value: DARK_CRM_GREEN_COLOR_NAME, label: "CRM green - dark" },
  { value: DARK_PROJECTS_ORANGE_COLOR_NAME, label: "Projects orange - dark" },
  { value: DARK_DEV_GREEN_COLOR_NAME, label: "Software green - dark" },
  { value: DARK_WORK_OS_IRIS_COLOR_NAME, label: "Work OS Iris - dark" },
  { value: SPACE_BLUE_COLOR_NAME, label: "Space blue" },
  { value: SIGN_UP_WITH_PRODUCTS_COLOR_NAME, label: "Sign up with products background" },
  { value: BACKGROUND_GRAY_COLOR_NAME, label: "Background gray" },
  { value: SPACE_BLUE_GRADIANT_COLOR_NAME, label: "Space blue gradiant" },
  { value: SPACE_BLUE_VIDEO_ONLY_COLOR_NAME, label: "Don't use - Space blue video only" },
  { value: SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME, label: "Don't use - Space blue video only - desktop" },
  { value: ELEVATE_BANNER_GRADIANT_COLOR_NAME, label: "Elevate Banner" },
  { value: ELEVATE_BUTTON_GRADIANT_COLOR_NAME, label: "Elevate Button" },
  { value: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR, label: "Brand & WM Primary Color" },
  { value: BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01, label: "Brand & WM Lighter Tint 01" },
  { value: BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02, label: "Brand & WM Light Tint 02" },
  { value: BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01, label: "Brand & WM Dark Tint 01" },
  { value: BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02, label: "Brand & WM Darker Tint 02" },
  { value: BRAND_PRIMARY_YELLOW, label: "Brand Primary Yellow" },
  { value: BRAND_YELLOW_DARK_TINT_01, label: "Brand Yellow Dark Tint 01" },
  { value: BRAND_YELLOW_DARKER_TINT_02, label: "Brand Yellow Darker Tint 02" },
  { value: BRAND_PRIMARY_RED, label: "Brand Primary Red" },
  { value: BRAND_RED_DARK_TINT_01, label: "Brand Red Dark Tint 01" },
  { value: BRAND_RED_DARKER_TINT_02, label: "Brand Red Darker Tint 02" },
  { value: BRAND_PRIMARY_GREEN, label: "Brand Primary Green" },
  { value: BRAND_GREEN_DARK_TINT_01, label: "Brand Green Dark Tint 01" },
  { value: BRAND_GREEN_DARKER_TINT_02, label: "Brand Green Darker Tint 02" },
  { value: BRAND_DARK_COLOR, label: "Dark" },
  { value: BRAND_BLACK_TEXT_COLOR, label: "Blackish Text Color" },
  { value: BRAND_BLACK_BACKGROUND_COLOR, label: "Black Background Color" },
  { value: CRM_PRIMARY_COLOR, label: "CRM Primary Color" },
  { value: CRM_LIGHTER_TINT_01, label: "CRM Lighter Tint 01" },
  { value: CRM_LIGHT_TINT_02, label: "CRM Light Tint 02" },
  { value: CRM_DARK_TINT_01, label: "CRM Dark Tint 01" },
  { value: CRM_DARKER_TINT_02, label: "CRM Darker Tint 02" },
  { value: DEV_PRIMARY_COLOR, label: "Dev Primary Color" },
  { value: DEV_LIGHTER_TINT_01, label: "Dev Lighter Tint 01" },
  { value: DEV_LIGHT_TINT_02, label: "Dev Light Tint 02" },
  { value: DEV_DARK_TINT_01, label: "Dev Dark Tint 01" },
  { value: DEV_DARKER_TINT_02, label: "Dev Darker Tint 02" },
  { value: MARKETER_PRIMARY_COLOR, label: "Marketer Primary Color" },
  { value: MARKETER_LIGHTER_TINT_01, label: "Marketer Lighter Tint 01" },
  { value: MARKETER_LIGHT_TINT_02, label: "Marketer Light Tint 02" },
  { value: MARKETER_DARK_TINT_01, label: "Marketer Dark Tint 01" },
  { value: MARKETER_DARKER_TINT_02, label: "Marketer Darker Tint 02" },
  { value: PROJECTS_PRIMARY_COLOR, label: "Projects Primary Color" },
  { value: PROJECTS_LIGHTER_TINT_01, label: "Projects Lighter Tint 01" },
  { value: PROJECTS_LIGHT_TINT_02, label: "Projects Light Tint 02" },
  { value: PROJECTS_DARK_TINT_01, label: "Projects Dark Tint 01" },
  { value: PROJECTS_DARKER_TINT_02, label: "Projects Darker Tint 02" },
  { value: PROJECTS_DARKEST_TINT_03, label: "Projects Darkest Tint 03" }
];

const productsColorOptionsOvertonesMap = {
  [MARKETER_PRIMARY_COLOR]: {
    light: MARKETER_LIGHT_TINT_02,
    dark: MARKETER_DARK_TINT_01
  },
  [CRM_PRIMARY_COLOR]: {
    light: CRM_LIGHT_TINT_02,
    dark: CRM_DARK_TINT_01
  },
  [PROJECTS_PRIMARY_COLOR]: {
    light: PROJECTS_LIGHT_TINT_02,
    dark: PROJECTS_DARK_TINT_01
  },
  [DEV_PRIMARY_COLOR]: {
    light: DEV_LIGHT_TINT_02,
    dark: DEV_DARK_TINT_01
  },
  [BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]: {
    light: BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
    dark: BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01
  }
};

const textColorOptions = [BRAND_BLACK_BACKGROUND_COLOR, WHITE_COLOR_NAME];

const signupButtonColorOptions = [
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  WHITE_COLOR_NAME,
  WORKFORMS_RED_COLOR_NAME
];

const newBrandColors = [
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
  BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
  BRAND_PRIMARY_YELLOW,
  BRAND_YELLOW_DARK_TINT_01,
  BRAND_YELLOW_DARKER_TINT_02,
  BRAND_PRIMARY_RED,
  BRAND_RED_DARK_TINT_01,
  BRAND_RED_DARKER_TINT_02,
  BRAND_PRIMARY_GREEN,
  BRAND_GREEN_DARK_TINT_01,
  BRAND_GREEN_DARKER_TINT_02,
  WHITE_COLOR_NAME,
  BRAND_DARK_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  BRAND_BLACK_TEXT_COLOR,
  CRM_PRIMARY_COLOR,
  CRM_LIGHTER_TINT_01,
  CRM_LIGHT_TINT_02,
  CRM_DARK_TINT_01,
  CRM_DARKER_TINT_02,
  DEV_PRIMARY_COLOR,
  DEV_LIGHTER_TINT_01,
  DEV_LIGHT_TINT_02,
  DEV_DARK_TINT_01,
  DEV_DARKER_TINT_02,
  MARKETER_PRIMARY_COLOR,
  MARKETER_LIGHTER_TINT_01,
  MARKETER_LIGHT_TINT_02,
  MARKETER_DARK_TINT_01,
  MARKETER_DARKER_TINT_02,
  PROJECTS_PRIMARY_COLOR,
  PROJECTS_LIGHTER_TINT_01,
  PROJECTS_LIGHT_TINT_02,
  PROJECTS_DARK_TINT_01,
  PROJECTS_DARKER_TINT_02,
  PROJECTS_DARKEST_TINT_03
];

const tagsSupportedColors = newBrandColors;
const productsColorOptions = newBrandColors;
const titleColorOptions = newBrandColors;

module.exports = {
  colorOptions,
  tagsSupportedColors,
  titleColorOptions,
  productsColorOptions,
  productsColorOptionsOvertonesMap,
  textColorOptions,
  signupButtonColorOptions,
  newBrandColors
};
