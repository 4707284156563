export const socialButtons = {
  type: "nested",
  label: "Social Buttons",
  fields: {
    show: { type: "boolean", label: "Show social buttons" },
    color: {
      type: "colorPicker",
      label: "Text Color",
      possibleColors: ["black", "white"]
    },
    blogStyle: { type: "boolean", label: "Blog style" }
  }
};
