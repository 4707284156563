import { FIELD_TYPES } from "constants/template-field-type";
import { buttonColors } from "segments/button-service";

export default {
  buttonText: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true },
  url: { type: FIELD_TYPES.TEXT, label: "Url" },
  color: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Color",
    possibleColors: buttonColors
  },
  isOpenLinkInNewTab: { type: FIELD_TYPES.BOOLEAN, label: "Open link in new tab" }
};
