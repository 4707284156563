import dynamic from "next/dynamic";

const WelcomeNewJoinersHeaderComponent = require("./welcome-new-joiners-header-component").default;

import WelcomeNewJoinersHeaderConfig from "./welcome-new-joiners-header-config";
import WelcomeNewJoinersHeaderAdvancedConfig from "./welcome-new-joiners-header-advanced-config";

export default {
  WelcomeNewJoinersHeaderComponent,
  WelcomeNewJoinersHeaderConfig,
  WelcomeNewJoinersHeaderAdvancedConfig
};
