import dynamic from "next/dynamic";

const BaseHeaderComponent = require("./base-header-component").default;

import BaseHeaderConfig from "./base-header-config";
import BaseHeaderAdvancedConfig from "./base-header-advanced-config";

export default {
  BaseHeaderComponent,
  BaseHeaderConfig,
  BaseHeaderAdvancedConfig
};
