import dynamic from "next/dynamic";

const WelcomeNewJoinersFooterComponent = require("./welcome-new-joiners-footer-component").default;

import WelcomeNewJoinersFooterConfig from "./welcome-new-joiners-footer-config";
import WelcomeNewJoinersFooterAdvancedConfig from "./welcome-new-joiners-footer-advanced-config";

export default {
  WelcomeNewJoinersFooterComponent,
  WelcomeNewJoinersFooterConfig,
  WelcomeNewJoinersFooterAdvancedConfig
};
