import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .dynamic-mobile-header-menu-component {
    height: 100%;
    width: 100%;
    background-color: ${Colors["white"]};
    user-select: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    padding: 0px 32px 80px;
    overflow: auto;

    .download-mobile-app-component-wrapper {
      margin-top: 32px;
    }
  }
`;
