// plop_marker:layouts_import
import BaseFooter from "./base-footer";
import RemoteWorkFooter from "./remote-work-footer";
import WelcomeNewJoinersFooter from "./welcome-new-joiners-footer";
import LongFooter from "./long-footer";
import SlimFooter from "./slim-footer";

export default {
  // plop_marker:layouts_add_layout
  BaseFooter,
  RemoteWorkFooter,
  WelcomeNewJoinersFooter,
  LongFooter,
  SlimFooter
};
