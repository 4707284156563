import css from "styled-jsx/css";
import { TOP_BAR } from "/constants/z-index";
import Constants from "./constants";
import BasicHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";

const BASIC_HEADER_HEIGHT = `${BasicHeaderConstants.BASIC_HEADER_HEIGHT}px`;
const HEADER_MENU_HEIGHT = `calc(100% - ${BasicHeaderConstants.BASIC_HEADER_HEIGHT - 1}px)`;
const HEADER_MENU_TRANSITION = `opacity ${Constants.HEADER_MENU_TRANSITION_DURATION}ms ease, visibility ${Constants.HEADER_MENU_TRANSITION_DURATION}ms ease;`;

export const componentStyles = css.global`
  .dynamic-header-mobile-component {
    width: 100%;
    height: ${BASIC_HEADER_HEIGHT};

    .mobile-header-content {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      .hamburger-menu {
        span {
          height: 2px !important;
        }
      }
    }

    .header-menu-component-wrapper {
      position: fixed;
      z-index: ${TOP_BAR};
      top: ${BasicHeaderConstants.BASIC_HEADER_HEIGHT - 1 + "px"};
      opacity: 0;
      height: ${HEADER_MENU_HEIGHT};
      width: 100%;
      visibility: hidden;
      transition: ${HEADER_MENU_TRANSITION};

      &.is-open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;
