import { PureComponent } from "react";
import { componentStyles } from "./new-joiners-footer-social-link-item-component.scss";
import colors from "styles/colors";

export default class NewJoinersFooterSocialLinkItemComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHover: false
    };
  }

  onMouseEnter = () => {
    this.setState({
      isHover: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHover: false
    });
  };

  render() {
    const { icon: Icon, label, url, color } = this.props;
    const { isHover } = this.state;

    return (
      <a
        href={url}
        className="new-joiners-footer-social-link-item-component"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="social-icon-wrapper">
          <Icon className="social-icon" style={{ fill: isHover ? color : colors["black"] }} />
        </div>

        <div className="social-label">{label}</div>

        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}
