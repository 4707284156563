import HeaderComponents from "segments/desktop/header";
import FooterComponents from "segments/desktop/footer";
import BannerComponents from "segments/desktop/banner";
import SegmentFamilies from "constants/segment-families";

export const isHeaderComponent = (segmentComponentName) => {
  return HeaderComponents[segmentComponentName];
};

export const isFooterComponent = (segmentComponentName) => {
  return FooterComponents[segmentComponentName];
};

export const isBannerComponent = (segmentComponentName) => {
  return BannerComponents[segmentComponentName];
};

export const isMainContentComponent = (segmentComponentName) => {
  // TODO: we might want to improve logic. For example assets are also not content component
  return (
    !isHeaderComponent(segmentComponentName) &&
    !isFooterComponent(segmentComponentName) &&
    !isBannerComponent(segmentComponentName)
  );
};

export const isShapeComponent = (segmentFamily) => {
  return segmentFamily === SegmentFamilies.SHAPE;
};
