import dynamic from "next/dynamic";

const DynamicHeaderComponent = require("./dynamic-header-component").default;

import DynamicHeaderConfig from "./dynamic-header-config";
import DynamicHeaderAdvancedConfig from "./dynamic-header-advanced-config";

export default {
  DynamicHeaderComponent,
  DynamicHeaderConfig,
  DynamicHeaderAdvancedConfig
};
