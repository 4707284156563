import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  RemoteWorkHeader,
  DevelopersCenterHeader,
  WelcomeNewJoinersHeader,
  DynamicHeader,
  BaseHeader,
  RegularHeader
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  RemoteWorkHeaderComponent: {
    type: "RemoteWorkHeaderComponent",
    title: "Remote Work",
    imgSrc: "/static/img/segments/layouts/remote-work-header.png",
    config: RemoteWorkHeader.RemoteWorkHeaderConfig,
    advancedConfig: RemoteWorkHeader.RemoteWorkHeaderAdvancedConfig,
    component: RemoteWorkHeader.RemoteWorkHeaderComponent
  },
  DevelopersCenterHeaderComponent: {
    type: "DevelopersCenterHeaderComponent",
    title: "Developers Center",
    imgSrc: "/static/img/segments/layouts/developers-center-header.png",
    config: DevelopersCenterHeader.DevelopersCenterHeaderConfig,
    advancedConfig: DevelopersCenterHeader.DevelopersCenterHeaderAdvancedConfig,
    component: DevelopersCenterHeader.DevelopersCenterHeaderComponent
  },
  WelcomeNewJoinersHeaderComponent: {
    type: "WelcomeNewJoinersHeaderComponent",
    title: "Welcome New Joiners",
    imgSrc: "/static/img/segments/layouts/welcome-new-joiners-header.png",
    config: WelcomeNewJoinersHeader.WelcomeNewJoinersHeaderConfig,
    advancedConfig: WelcomeNewJoinersHeader.WelcomeNewJoinersHeaderAdvancedConfig,
    component: WelcomeNewJoinersHeader.WelcomeNewJoinersHeaderComponent
  },
  DynamicHeaderComponent: {
    type: "DynamicHeaderComponent",
    title: "Dynamic",
    imgSrc: "/static/img/segments/layouts/dynamic-header.png",
    config: DynamicHeader.DynamicHeaderConfig,
    advancedConfig: DynamicHeader.DynamicHeaderAdvancedConfig,
    component: DynamicHeader.DynamicHeaderComponent
  },
  BaseHeaderComponent: {
    type: "BaseHeaderComponent",
    title: "Base",
    imgSrc: "/static/img/segments/layouts/base-header.png",
    config: BaseHeader.BaseHeaderConfig,
    advancedConfig: BaseHeader.BaseHeaderAdvancedConfig,
    component: BaseHeader.BaseHeaderComponent
  },
  RegularHeaderComponent: {
    type: "RegularHeaderComponent",
    title: "Regular",
    imgSrc: "/static/img/segments/layouts/regular-header.png",
    config: RegularHeader.RegularHeaderConfig,
    advancedConfig: RegularHeader.RegularHeaderAdvancedConfig,
    component: RegularHeader.RegularHeaderComponent
  }
};
