import { PureComponent } from "react";
import { componentStyles } from "./remote-work-footer-component.scss";
import ShortFooter from "components/core/short-footer/short-footer";

export default class RemoteWorkFooterComponent extends PureComponent {
  render() {
    return (
      <div className="remote-work-footer-component">
        <ShortFooter {...this.props} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RemoteWorkFooterComponent.defaultProps = {};
