export const extendTrialCookie = {
  type: "nested",
  label:
    "Extend Trial Cookie, (Get token here: https://forms.monday.com/forms/4555757001e20a941a5d413f8953e80e?r=use1)",
  fields: {
    token: {
      type: "text",
      label: "Dev Token For Extending"
    },
    daysToExpire: {
      type: "text",
      label: "Expire Days From Visit"
    }
  }
};
