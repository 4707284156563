import dynamic from "next/dynamic";

const PlainBannerComponent = require("./plain-banner-component").default;

import PlainBannerConfig from "./plain-banner-config";
import PlainBannerAdvancedConfig from "./plain-banner-advanced-config";

export default {
  PlainBannerComponent,
  PlainBannerConfig,
  PlainBannerAdvancedConfig
};
