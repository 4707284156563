import dynamic from "next/dynamic";

const WithBackgroundBannerComponent = require("./with-background-banner-component").default;

import WithBackgroundBannerConfig from "./with-background-banner-config";
import WithBackgroundBannerAdvancedConfig from "./with-background-banner-advanced-config";

export default {
  WithBackgroundBannerComponent,
  WithBackgroundBannerConfig,
  WithBackgroundBannerAdvancedConfig
};
