import dynamic from "next/dynamic";

const DevelopersCenterHeaderComponent = require("./developers-center-header-component").default;

import DevelopersCenterHeaderConfig from "./developers-center-header-config";
import DevelopersCenterHeaderAdvancedConfig from "./developers-center-header-advanced-config";

export default {
  DevelopersCenterHeaderComponent,
  DevelopersCenterHeaderConfig,
  DevelopersCenterHeaderAdvancedConfig
};
