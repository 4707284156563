import { FIELD_TYPES } from "constants/template-field-type";
import { SLIM, LONG } from "./footer-consts";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Base",
  fields: {
    footer_type: {
      type: FIELD_TYPES.COMBO,
      label: "Footer Type",
      options: [
        { value: SLIM, text: "Slim (For landing pages)" },
        { value: LONG, text: "Long (For website pages)" },
      ]
    }
  }
};
