import { FIELD_TYPES } from "constants/template-field-type";
import { PARAGRAPH_AND_ASSET_VIDEOS } from "constants/video-types";

export const asset = {
  type: FIELD_TYPES.NESTED,
  label: "Asset",
  fields: {
    shadow: { type: FIELD_TYPES.BOOLEAN, label: "Shadow Frame" },
    convertGifToVideo: { type: FIELD_TYPES.BOOLEAN, label: "Convert gif to video" },
    side: {
      type: FIELD_TYPES.COMBO,
      label: "Asset Location",
      options: ["left", "right"]
    },
    image: {
      type: FIELD_TYPES.NESTED,
      label: "Image",
      fields: {
        src: {
          type: FIELD_TYPES.GALLERY,
          defaultImagesKeys: ["static/img/templates/long-template/boards/"],
          label: "Select an Image"
        },
        alt: { type: FIELD_TYPES.TEXT, label: "Image description (alt)" },
        href: { type: FIELD_TYPES.TEXT, label: "Href" }
      }
    },

    video: {
      type: FIELD_TYPES.NESTED,
      label: "Add a Video",
      fields: {
        src: {
          type: FIELD_TYPES.CLOUDINARY_VIDEO_PICKER,
          label: "Video",
          videoType: PARAGRAPH_AND_ASSET_VIDEOS
        },
        loop: {
          type: FIELD_TYPES.BOOLEAN,
          label: "Loop",
          options: ["Yes", "No"]
        },
        playWhenVisible: {
          type: FIELD_TYPES.BOOLEAN,
          label: "Play Only When Visible",
          options: ["Yes", "No"]
        }
      }
    }
  }
};
