import { FIELD_TYPES } from "constants/template-field-type";
import { PARAGRAPH_AND_ASSET_VIDEOS } from "constants/video-types";

export const paragraphAndAssetColumns = {
  type: FIELD_TYPES.NESTED,
  label: "Text and Asset",
  fields: {
    content: {
      type: FIELD_TYPES.NESTED,
      label: "Content",
      fields: {
        topic: {
          type: FIELD_TYPES.NESTED,
          label: "Topic",
          fields: {
            text: { type: FIELD_TYPES.TEXT, label: "Text" },
            color: { type: FIELD_TYPES.COLOR_PICKER, label: "Color" }
          }
        },
        image: {
          type: FIELD_TYPES.NESTED,
          label: "Title image",
          fields: {
            src: {
              type: FIELD_TYPES.GALLERY,
              defaultImagesKeys: ["static/img/templates/long-template/boards/"],
              label: "Select an Image"
            },
            alt: { type: FIELD_TYPES.TEXT, label: "Image description (alt)" }
          }
        },
        title: { type: FIELD_TYPES.TEXT, label: "Title" },
        body: { type: FIELD_TYPES.MULTIPLE_TEXTS, label: "Content", textArea: true, maxLineChars: 574 },
        bottomLink: {
          type: FIELD_TYPES.NESTED,
          label: "Bottom Link",
          fields: {
            text: { type: FIELD_TYPES.TEXT, label: "Text" },
            href: { type: FIELD_TYPES.TEXT, label: "Url" }
          }
        }
      }
    },

    asset: {
      type: FIELD_TYPES.NESTED,
      label: "Asset",
      fields: {
        shadow: { type: FIELD_TYPES.BOOLEAN, label: "Shadow Frame" },
        side: {
          type: FIELD_TYPES.COMBO,
          label: "Asset Location",
          options: ["left", "right"]
        },
        image: {
          type: FIELD_TYPES.NESTED,
          label: "Image",
          fields: {
            src: {
              type: FIELD_TYPES.GALLERY,
              defaultImagesKeys: ["static/img/templates/long-template/boards/"],
              label: "Select an Image"
            },
            alt: { type: FIELD_TYPES.TEXT, label: "Image description (alt)" }
          }
        },

        video: {
          type: FIELD_TYPES.NESTED,
          label: "Add a Video",
          fields: {
            src: {
              type: FIELD_TYPES.VIDEO_PICKER,
              label: "Video",
              videoType: PARAGRAPH_AND_ASSET_VIDEOS
            },
            loop: {
              type: FIELD_TYPES.BOOLEAN,
              label: "Loop",
              options: ["Yes", "No"]
            },
            playWhenVisible: {
              type: FIELD_TYPES.BOOLEAN,
              label: "Play Only When Visible",
              options: ["Yes", "No"]
            }
          }
        }
      }
    }
  }
};
