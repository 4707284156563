export const directoryListData = {
  type: "segments",
  label: "Pages",
  types: {
    link: {
      type: "nested",
      label: "Link",
      fields: {
        image: {
          type: "gallery",
          label: "Select an image",
          defaultImagesKeys: ["static/img/templates/directory-list-data/thumbnails/"]
        },
        firstTitle: {
          type: "nested",
          label: "First Title Line",
          fields: {
            text: { type: "text", label: "Text" },
            decorator: { type: "text", label: "Text decorator" }
          }
        },
        secondTitle: {
          type: "nested",
          label: "Second Title Line",
          fields: {
            text: { type: "text", label: "Text" },
            decorator: { type: "text", label: "Text decorator" }
          }
        },
        subtitle: { type: "text", label: "Subtitle (Appears on hover)" },
        link: { type: "text", label: "Thumbnail Link (Appears on hover)" },
        buttomLink: {
          type: "nested",
          label: "Buttom Link (Appears on hover)",
          fields: {
            show: { type: "boolean", label: "Should Show link" },
            text: { type: "text", label: "Link text" },
            url: { type: "text", label: "URL / Path" },
            color: {
              type: "colorPicker",
              label: "Text Color",
              possibleColors: ["brand-blue", "black"]
            }
          }
        },
        hoverDecorator: {
          type: "nested",
          label: "Hover Decorator (Appears on hover)",
          fields: {
            show: { type: "boolean", label: "Should Show" },
            text: { type: "text", label: "Text" }
          }
        }
      }
    }
  }
};
