export const comparisonTable = {
  type: "nested",
  label: "Comparison Table",
  fields: {
    title: { type: "text", label: "Title" },
    subtitle: { type: "text", label: "Subtitle" },
    competitorLogo: {
      type: "nested",
      label: "Competitor",
      fields: {
        src: {
          type: "gallery",
          defaultImagesKeys: ["static/img/templates/comparison-table/competitors/"],
          label: "Competitor Logo",
          options: [
            { value: "/static/img/competitors/trello.png", text: "Trello" },
            { value: "/static/img/competitors/asana.png", text: "Asana" },
            {
              value: "/static/img/competitors/atlassian.png",
              text: "Atlassian"
            },
            {
              value: "/static/img/competitors/basecamp.png",
              text: "Basecamp"
            },
            {
              value: "/static/img/competitors/confluence.png",
              text: "Confluence"
            },
            { value: "/static/img/competitors/jira.png", text: "Jira" },
            {
              value: "/static/img/competitors/sharepoint.png",
              text: "SharePoint"
            },
            { value: "/static/img/competitors/slack.png", text: "Slack" },
            { value: "/static/img/competitors/todoist.png", text: "Todoist" },
            {
              value: "/static/img/competitors/smartsheet.png",
              text: "Smartsheet"
            },
            { value: "/static/img/competitors/wrike.png", text: "Wrike" },
            {
              value: "/static/img/competitors/wunderlist.png",
              text: "Wunderlist"
            },
            { value: "/static/img/competitors/yammer.png", text: "Yammer" }
          ]
        },
        height: {
          type: "text",
          label: "Image height % (optional, 50-100)"
        }
      }
    },
    features: {
      type: "segments",
      label: "Features",
      types: {
        feature: {
          type: "nested",
          fields: {
            title: {
              type: "combo",
              label: "Feature Title",
              options: [
                "In app communication",
                "Gantt chart",
                "Time management",
                "Files management",
                "Supports agile",
                "Budget management",
                "Export dashboards",
                "Mobile app",
                "Use templates",
                "24/7 support",
                "Subtasks",
                "Shareable dashboards",
                "Deadline management",
                "Time tracking",
                "Email notifications",
                "Fun giphys",
                "Client management ",
                "Full API",
                "Integrations",
                "Import from Excel"
              ],
              otherOption: {
                text: "Put your own feature...",
                inputPlaceholder: "Put your own feature..."
              }
            },
            monday: {
              type: "combo",
              label: "Monday",
              options: [{ value: "true", text: "Yes" }, { value: "false", text: "No" }],
              otherOption: {
                text: "Put your own text...",
                inputPlaceholder: "Put your own URL..."
              }
            },
            competitor: {
              type: "combo",
              label: "Competitor",
              options: [{ value: "true", text: "Yes" }, { value: "false", text: "No" }],
              otherOption: {
                text: "Put your own text...",
                inputPlaceholder: "Put your own text..."
              }
            },
            bigTexts: {
              type: "boolean",
              label: "Big Texts"
            }
          }
        }
      }
    }
  },
  defaultData: {
    comparisonTable: {
      title: "May the best product win!",
      subtitle: "monday.com is powerful enough to track everything your team is working on—so you can get results.",
      features: []
    }
  }
};
