import { FIELD_TYPES } from "constants/template-field-type";

export const clickableImageGallery = {
  type: FIELD_TYPES.MULTIPLE_NESTED,
  label: "Image With Text",
  maxLimit: 10,
  nestedConfig: {
    type: FIELD_TYPES.NESTED,

    fields: {
      url: {
        type: FIELD_TYPES.GALLERY,
        label: "Image",
        defaultImagesKeys: []
      },
      href: {
        type: FIELD_TYPES.TEXT,
        label: "Link To"
      }
    }
  }
};
