import { PureComponent } from "react";
import { componentStyles } from "./welcome-new-joiners-header-component.scss";
import DynamicHeaderComponent from "segments/desktop/header/layouts/dynamic-header/dynamic-header-component";
import { getCookie } from "/services/cookies-service";
import { HR_MANAGER_SITE_COOKIE_NAME } from "constants/hr-onboarding-consts";
import {
  MONDAY_WELCOME_NEW_JOINERS_LOGO_SRC,
  HR_HOME_LINK,
  HR_WHO_ARE_WE_LINK,
  HR_MY_TEAMS_LINKS_LINK,
  HR_KNOWLEDGE_BASE_LINK,
  HR_IMPORTANT_INFORMATION,
  HR_BENEFITS_LINK,
  HR_ONBOARDING_LINK,
  HR_MANAGERS_HOME_LINK,
  HR_MANAGERS_ONBOARDING_LINK,
  HR_MANAGERS_KNOWLEDGE_BASE_LINK
} from "constants/links";

const getLinkItems = (isManagerSite) => {
  return [
    {
      label: "Who we are",
      url: HR_WHO_ARE_WE_LINK
    },
    {
      label: "My onboarding",
      url: isManagerSite ? HR_MANAGERS_ONBOARDING_LINK : HR_ONBOARDING_LINK
    },
    {
      label: "Our teams",
      url: HR_MY_TEAMS_LINKS_LINK
    },
    {
      label: "Knowledge base",
      url: isManagerSite ? HR_MANAGERS_KNOWLEDGE_BASE_LINK : HR_KNOWLEDGE_BASE_LINK
    },
    {
      label: "Benefits",
      url: HR_BENEFITS_LINK
    },
    {
      label: "Important information",
      url: HR_IMPORTANT_INFORMATION
    }
  ];
};

const getLogoImage = (isManagerSite) => {
  return { src: MONDAY_WELCOME_NEW_JOINERS_LOGO_SRC, href: isManagerSite ? HR_MANAGERS_HOME_LINK : HR_HOME_LINK };
};

export default class WelcomeNewJoinersHeaderComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      linkItems: getLinkItems(false),
      logoImage: getLogoImage(false)
    };
  }

  componentDidMount() {
    const isManagerSite = getCookie(HR_MANAGER_SITE_COOKIE_NAME);

    this.setState({ linkItems: getLinkItems(isManagerSite), logoImage: getLogoImage(isManagerSite) });
  }

  render() {
    const { linkItems, logoImage } = this.state;

    return (
      <div className="welcome-new-joiners-header-component">
        <DynamicHeaderComponent linkItems={linkItems} logoImage={logoImage} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

WelcomeNewJoinersHeaderComponent.defaultProps = {};
