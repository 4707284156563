import css from "styled-jsx/css";
import Colors from "styles/colors";
import { TOP_LAYER, ALWAYS_ON_TOP} from "constants/z-index";

const HOVER_BORDER = `2px dashed #c6e2e8`; // hard-coded color so no one else will use it
const SELECTED_BORDER = `2px solid ${Colors["bold-blue"]}`;

export const componentStyles = css.global`
  .preview-base-segment-component {
    height: 100%;
    width: 100%;
    position: relative;
    user-select: none;
    background: white;
    cursor: pointer;

    &.preview-base-template-draggable-helper-element {
      overflow: hidden;
      box-shadow: 0px 2px 16px 0 rgba(0, 0, 0, 0.2);
      cursor: move;

      .preview-overlay,
      .base-layout-segment-component {
        zoom: 50%;
      }
    }

    &:hover,
    &.is-selected,
    &.preview-base-template-draggable-helper-element {
      .preview-overlay {
        border: ${HOVER_BORDER};
        opacity: 1;
      }
    }

    &.is-selected {
      .preview-overlay {
        border: ${SELECTED_BORDER};
      }
    }

    .preview-overlay {
      position: absolute;
      display: flex;
      padding: 32px 0 0 32px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${ALWAYS_ON_TOP};
      transition: opacity 200ms ease;
      opacity: 0;
      border: 2px solid transparent;
      pointer-events: none;

      .icon-wrapper {
        position: relative;
        margin: 6px;
        background: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 150ms ease;
        pointer-events: all;
        z-index:${TOP_LAYER};

        &:hover {
          transform: scale(1.1);
        }

        svg {
          height: 36px;
          width: 36px;
        }

        &.drag-icon-wrapper {
          cursor: move;
        }

        &.edit-icon-wrapper {
          cursor: pointer;
        }

        &.remove-icon-wrapper {
          cursor: pointer;
        }

        &.copy-icon-wrapper {
          cursor: pointer;
        }

        &.paste-icon-wrapper {
          cursor: pointer;
        }

        &.disabled{
          background: #d6d4d4;
          cursor: auto;
          
          &:hover{
            transform: scale(1);
          }
        }
      }
    }
  }

  .icon-wrapper-tooltip{
    font-size: 1.238rem;
    top: 45px !important;
    z-index: ${TOP_LAYER};
  }

  .has-copied {
    position: absolute;
    bottom: -20px;
    font-size: 1rem;  
}
`;
