import { FULL_PAGE_BACKGROUND_VIDEOS, FULL_HEIGHT_VIDEOS } from "constants/video-types";
import { MOBILE_VERSIONS } from "/constants/one-pager-asset-types";

const imageAlign = {
  type: "combo",
  label: "Desktop image alignment",
  options: [
    {
      value: "align-center",
      text: "Center"
    },
    {
      value: "align-top",
      text: "Top"
    },
    {
      value: "align-bottom",
      text: "Bottom"
    }
  ]
};

export const textPart = {
  type: "nested",
  label: "Text Config",
  fields: {
    color: {
      type: "colorPicker",
      label: "Text Color",
      possibleColors: ["black", "white"]
    },
    titleLines: {
      type: "multipleTexts",
      label: "Title Lines (Up to 2, 250 chars per line)",
      maxLimit: 2,
      maxLineChars: 250
    },
    titleLinesFontSize: {
      type: "text",
      label: "Desktop title lines font size"
    },
    subtitleLines: {
      type: "multipleTexts",
      label: "Subtitle Lines (Up to 2, 150 chars per line)",
      maxLimit: 2,
      maxLineChars: 150
    },
    bullets: {
      type: "multipleTexts",
      label: "Bullets (Up to 3)",
      maxLimit: 3
    },
    mobileTitleLines: {
      type: "multipleTexts",
      label: "Mobile Title Lines (Up to 3, if 3 subtitle won't appear, max 40 chars per line)",
      maxLimit: 3,
      maxLineChars: 40
    },
    mobileSubtitleLines: {
      type: "multipleTexts",
      label: "Mobile Subtitle Lines (Up to 2), max 40 chars per line",
      maxLimit: 3,
      maxLineChars: 40
    }
  }
};

export const assetPart = {
  type: "dynamic",
  label: "Asset Type",
  types: {
    video: {
      type: "videoPicker",
      label: "Video Type",
      videoType: FULL_PAGE_BACKGROUND_VIDEOS
    }
  }
};

export const fullHeightVideoPart = {
  type: "dynamic",
  label: "Asset Type",
  types: {
    video: {
      type: "videoPicker",
      label: "Video Type",
      videoType: FULL_HEIGHT_VIDEOS
    }
  }
};

export const imageWithBackgroundPart = {
  type: "nested",
  label: "Image and Backgound",
  fields: {
    imageSegmentHeight: {
      type: "text",
      label: "The height of the first screen (Must be defined by vh)"
    },
    backgroundColor: {
      type: "colorPicker",
      label: "Background Color",
      possibleColors: ["brand-blue", "brand-purple", "brand-dark-blue", "brand-green", "transparent"]
    },
    imageAlign,
    straightBorder: { type: "boolean", label: "Straight border" },
    src: {
      type: "gallery",
      defaultImagesKeys: ["static/img/templates/one-pager/boards/"],
      label: "Select an image",
      options: [
        {
          value: "/static/img/boards/board-with-cactus-and-phone.png",
          text: "Board with cactus and phone"
        },
        {
          value: "/static/img/boards/board-with-column-picker.jpg",
          text: "Board with column picker"
        },
        {
          value: "/static/img/boards/board-with-conversation.png",
          text: "Board with conversation"
        },
        {
          value: "/static/img/boards/board-with-earphones.png",
          text: "Board with earphones"
        },
        {
          value: "/static/img/boards/board-with-notifications.jpg",
          text: "Board with notifications"
        },
        {
          value: "/static/img/boards/board-with-pencils.png",
          text: "Board with pencils"
        },
        {
          value: "/static/img/boards/board-with-person-picker.jpg",
          text: "Board with person picker"
        },
        {
          value: "/static/img/boards/board-with-status-menu.jpg",
          text: "Board with status menu"
        },
        {
          value: "/static/img/boards/board-with-yellow-coffee-cup.png",
          text: "Board with yellow coffee cup"
        },
        {
          value: "/static/img/boards/cactus-with-board.png",
          text: "Board with cactus"
        },
        {
          value: "/static/img/boards/upload-file-board.jpg",
          text: "Board with upload file"
        }
      ]
    },
    alt: {
      type: "text",
      label: "Image description (important for SEO)"
    }
  }
};

export const mobileType = {
  type: "combo",
  label: "Select page mobile version",
  options: [
    {
      value: MOBILE_VERSIONS.MOBILE_BACKGROUND_GIF,
      text: "Mobile background gif"
    },
    {
      value: MOBILE_VERSIONS.MOBILE_ASSET,
      text: "Mobile Asset"
    }
  ]
};

export const mobileAssetType = {
  type: "gallery",
  label: "Mobile asset (Gif). Appears only in case mobile version is 'Mobile Asset'",
  defaultImagesKeys: ["static/img/templates/one-pager/mobile/"]
};
