// plop_marker:layouts_import
import RemoteWorkHeader from "./remote-work-header";
import DevelopersCenterHeader from "./developers-center-header";
import WelcomeNewJoinersHeader from "./welcome-new-joiners-header";
import DynamicHeader from "./dynamic-header";
import BaseHeader from "./base-header";
import RegularHeader from "./regular-header";

export default {
  // plop_marker:layouts_add_layout
  RemoteWorkHeader,
  DevelopersCenterHeader,
  WelcomeNewJoinersHeader,
  DynamicHeader,
  BaseHeader,
  RegularHeader
};
