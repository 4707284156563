import { PureComponent } from "react";
import ButtonComponent, { WHITE } from "components/core/new-button/button-component";
import colors from "styles/colors";
import { componentStyles } from "./developers-banner-component.scss";

export default class DevelopersBannerComponent extends PureComponent {
  onClick = () => {
    window.open("https://monday-apps.readme.io/docs", "_blank");
  };

  render() {
    return (
      <div className="developers-banner-component">
        <div className="banner-text">
          We're putting the final touches on a new documentation center! Take a sneak-peek here.
        </div>
        <div className="banner-button-wrapper">
          <ButtonComponent onClick={this.onClick} textColor={colors["dodger-blue"]} color={WHITE} isWithArrow={true}>
            Try now
          </ButtonComponent>
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
