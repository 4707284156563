import { PureComponent } from "react";
import { componentStyles } from "./welcome-new-joiners-footer-component.scss";
import NewJoinersFooterSocialLinkItemComponent from "./new-joiners-footer-social-link-item/new-joiners-footer-social-link-item-component";
import LocationPinIcon from "static/svg/location-pin.svg";
import CircelFacebookIcon from "static/svg/circle-facebook.svg";
import CircelTwitterIcon from "static/svg/circle-twitter.svg";
import CircelInstagramIcon from "static/svg/circle-instagram.svg";
import CircelLinkedinIcon from "static/svg/circle-linkedin.svg";
import CircelYoutubeIcon from "static/svg/circle-youtube.svg";
import {
  MONDAY_TWITTER_LINK,
  MONDAY_LINKEDIN_LINK,
  MONDAY_FACEBOOK_LINK,
  MONDAY_YOUTUBE_LINK,
  MONDAY_INSTAGRAM_LINK
} from "constants/links";

const SOCIAL_LINKS = [
  {
    icon: CircelFacebookIcon,
    label: "Facebook",
    url: MONDAY_FACEBOOK_LINK,
    color: "#4B69B0"
  },
  {
    icon: CircelTwitterIcon,
    label: "Twitter",
    url: MONDAY_TWITTER_LINK,
    color: "#37B1E1"
  },
  {
    icon: CircelInstagramIcon,
    label: "Instagram",
    url: MONDAY_INSTAGRAM_LINK,
    color: "#C6356A"
  },
  {
    icon: CircelLinkedinIcon,
    label: "Linkedin",
    url: MONDAY_LINKEDIN_LINK,
    color: "#0678B6"
  },
  {
    icon: CircelYoutubeIcon,
    label: "Youtube",
    url: MONDAY_YOUTUBE_LINK,
    color: "#E83F3A"
  }
];

export default class WelcomeNewJoinersFooterComponent extends PureComponent {
  renderSocialLinks() {
    return SOCIAL_LINKS.map((socialLink, index) => {
      return (
        <div className="new-joiners-footer-social-link-item-component-wrapper" key={index}>
          <NewJoinersFooterSocialLinkItemComponent {...socialLink} />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="welcome-new-joiners-footer-component">
        <div className="welcome-new-joiners-footer-content">
          <div className="monday-address-container">
            <div className="office-location-wrapper tel-aviv-office">
              <div className="location-pin-icon-wrapper">
                <LocationPinIcon className="location-pin-icon" />
              </div>

              <div className="monday-address">
                <div>6 Yitzhak Sadeh St, Tel Aviv 6777506, Israel</div>
              </div>
            </div>

            <div className="office-location-wrapper new-york-office">
              <div className="location-pin-icon-wrapper">
                <LocationPinIcon className="location-pin-icon" />
              </div>

              <div className="monday-address">
                <div>246 5th Avenue, New York, NY 10001, USA</div>
              </div>
            </div>

            <div className="office-location-wrapper australia-office">
              <div className="location-pin-icon-wrapper">
                <LocationPinIcon className="location-pin-icon" />
              </div>

              <div className="monday-address">
                <div>Lvl 12, 64 York St, Sydney, Australia</div>
              </div>
            </div>

            <div className="office-location-wrapper london-office">
              <div className="location-pin-icon-wrapper">
                <LocationPinIcon className="location-pin-icon" />
              </div>

              <div className="monday-address">
                <div>179 Great Portland St. W1W 5PL, London, UK</div>
              </div>
            </div>
          </div>

          <div className="social-links-wrapper">{this.renderSocialLinks()}</div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

WelcomeNewJoinersFooterComponent.defaultProps = {};
