import dynamic from "next/dynamic";

const RemoteWorkFooterComponent = require("./remote-work-footer-component").default;

import RemoteWorkFooterConfig from "./remote-work-footer-config";
import RemoteWorkFooterAdvancedConfig from "./remote-work-footer-advanced-config";

export default {
  RemoteWorkFooterComponent,
  RemoteWorkFooterConfig,
  RemoteWorkFooterAdvancedConfig
};
