import { PureComponent } from "react";
import { componentStyles } from "./dynamic-header-desktop-component.scss";
import BasicHeaderComponent from "segments/desktop/header/layouts/components/basic-header/basic-header-component";
import LinkItemComponent from "segments/desktop/header/layouts/base-header/base-header-desktop/link-item/link-item-component";

export default class DynamicHeaderDesktopComponent extends PureComponent {
  renderLinkItems(isRightAlignedLinks) {
    const { linkItems } = this.props;
    const relevantLinkItems = linkItems.filter((linkItem) => !!linkItem.desktopAlignRight === isRightAlignedLinks);

    return relevantLinkItems.map((linkItem, index) => {
      const { label, url } = linkItem;

      return (
        <div key={index} className="link-item-component-wrapper">
          <LinkItemComponent title={label} url={url} />
        </div>
      );
    });
  }

  render() {
    const { logoImage } = this.props;
    const { src, href, style } = logoImage;

    return (
      <header className="dynamic-header-desktop-component">
        <BasicHeaderComponent imageSrc={src} imageHref={href} imageStyle={style}>
          <div className="dynamic-header-links">
            <div className="left-side-header-links">{this.renderLinkItems(false)}</div>
            <div className="right-side-header-links">{this.renderLinkItems(true)}</div>
          </div>
        </BasicHeaderComponent>
        <style jsx>{componentStyles}</style>
      </header>
    );
  }
}
