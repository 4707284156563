import { PureComponent } from "react";
import { componentStyles } from "./regular-header-component.scss";
import BaseHeaderComponent from "segments/desktop/header/layouts/base-header/base-header-component";

export default class RegularHeaderComponent extends PureComponent {
  render() {
    return (
      <div className="regular-header-component">
        <BaseHeaderComponent {...this.props} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RegularHeaderComponent.defaultProps = {
  showMenu: true,
  shortHeader: false
};
