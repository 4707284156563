import { FIELD_TYPES } from "constants/template-field-type";
import additionalTextConfig from "segments/desktop/core-components/button/additional-text-config";
import { signupButtonColorOptions } from "constants/color-picker-constants";

export default {
  buttonColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Color",
    possibleColors: signupButtonColorOptions
  },
  useCtaText: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Use override default CTA"
  },
  ctaText: {
    type: FIELD_TYPES.TEXT,
    label: "Override Default CTA",
    translatable: true
  },
  signupWithsolutionAppReferenceId: {
    type: FIELD_TYPES.TEXT,
    label: "Solution Id:",
    labelDescription: "Leave blank unless signup with solution needed - enter the solution's app reference id"
  },
  ...additionalTextConfig,
  buttonId: {
    type: FIELD_TYPES.TEXT,
    label: "Button ID",
    labelDescription: "board_kind in signup_main_cta_clicked BB event"
  },
  isMobileInputTransparent: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Use mobile transparent input",
    labelDescription: "To be used in case of white button"
  },
  signupProductId: {
    type: FIELD_TYPES.PRODUCTS,
    label: "Product",
    labelDescription: "Signup to monday product"
  },
  signupClusterId: {
    type: FIELD_TYPES.CLUSTERS,
    label: "Cluster",
    labelDescription: "Use only in generic pages if you want to assign a cluster"
  },
  showGoogleConnectOnMobile: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Mobile google connect",
    labelDescription: "Display google connect option in mobile pages"
  }
};
