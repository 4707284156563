import { FIELD_TYPES } from "constants/template-field-type";

export const mobileImageWidthRatio = {
  type: FIELD_TYPES.COMBO,
  label: "Mobile Image width Ratio",
  options: [
    "0.1",
    "0.2",
    "0.3",
    "0.4",
    "0.5",
    { value: "0.6", text: "0.6 (Default)" },
    "0.7",
    "0.8",
    "0.9",
    "1"
  ]
};
