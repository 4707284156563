import dynamic from "next/dynamic";

const RemoteWorkHeaderComponent = require("./remote-work-header-component").default;

import RemoteWorkHeaderConfig from "./remote-work-header-config";
import RemoteWorkHeaderAdvancedConfig from "./remote-work-header-advanced-config";

export default {
  RemoteWorkHeaderComponent,
  RemoteWorkHeaderConfig,
  RemoteWorkHeaderAdvancedConfig
};
