import { FIELD_TYPES } from "constants/template-field-type";
import { signupForm } from "/components/templates-generator/config/components/signup-form/signup-form";

export const paragraph = {
  type: FIELD_TYPES.NESTED,
  label: "Paragraph",
  fields: {
    textDirection: {
      type: FIELD_TYPES.COMBO,
      label: "Text Direction",
      options: [{ value: "left", text: "Left" }, { value: "center", text: "Center" }, { value: "right", text: "Right" }]
    },
    topic: {
      type: FIELD_TYPES.NESTED,
      label: "Topic",
      fields: {
        text: { type: FIELD_TYPES.TEXT, label: "Text" },
        color: { type: FIELD_TYPES.COLOR_PICKER, label: "Color" }
      }
    },
    title: { type: FIELD_TYPES.TEXT, label: "Title" },
    titleFontSize: { type: FIELD_TYPES.TEXT, label: "Title Font Size" },
    body: { type: FIELD_TYPES.MULTIPLE_TEXTS, label: "Body" },
    showSignup: { type: FIELD_TYPES.BOOLEAN, label: "Show Signup" },
    signupForm: signupForm
  }
};

const paragraphWithBottomLinkConfig = paragraph;
paragraphWithBottomLinkConfig.fields["bottomLink"] = {
  type: FIELD_TYPES.NESTED,
  label: "Bottom Link",
  fields: {
    text: { type: FIELD_TYPES.TEXT, label: "Text" },
    href: { type: FIELD_TYPES.TEXT, label: "Url" }
  }
};

export const paragraphWithBottomLink = paragraphWithBottomLinkConfig;
