import { FIELD_TYPES } from "constants/template-field-type";
import { paragraphWithBottomLink } from "../paragraph/paragraph-config";

export const imageGalleryWithParagraph = {
  type: FIELD_TYPES.MULTIPLE_NESTED,
  label: "Image With Paragraph",
  maxLimit: 10,
  nestedConfig: {
    type: FIELD_TYPES.NESTED,

    fields: {
      url: {
        type: FIELD_TYPES.GALLERY,
        label: "Image",
        defaultImagesKeys: []
      },
      paragraph: paragraphWithBottomLink
    }
  }
};
