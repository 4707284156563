import dynamic from "next/dynamic";

const SlimFooterComponent = require("./slim-footer-component").default;

import SlimFooterConfig from "./slim-footer-config";
import SlimFooterAdvancedConfig from "./slim-footer-advanced-config";

export default {
  SlimFooterComponent,
  SlimFooterConfig,
  SlimFooterAdvancedConfig
};
