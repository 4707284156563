import { withGenericConfig } from "contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import ShortFooter from "components/core/short-footer/short-footer";
import FooterCategoryComponent from "segments/desktop/core-components/long-footer/footer-category/footer-category-component";
import { componentStyles as longFooterComponentStyles } from "segments/desktop/core-components/long-footer/long-footer.scss";
import { SocialFooterLinks } from "/constants/links";
import FooterBottomBarComponent from "segments/desktop/core-components/long-footer/footer-bottom-bar/footer-bottom-bar-component";
import { getFooterComponents } from "/services/localization/localization-service";
import classnames from "classnames";
import { componentStyles } from "./slim-footer.scss";
import DefaultProps from "./default-props";

const SlimFooter = (props) => {
  const { translate, localeId, abTests, pageClusterConfig, useProductMiniSiteConfig } = props;

  const renderShortFooter = () => {
    return <ShortFooter {...props} />;
  };

  const renderSlimFooter = () => {
    const getFullFooterForLocalized = true;
    const footerComponents = getFooterComponents(localeId, abTests, getFullFooterForLocalized, {
      pageClusterConfig,
      useProductMiniSiteConfig
    });

    const { SlimFooterLinks } = footerComponents;

    return (
      <footer className={classnames("footer-container", "slim-footer")}>
        <div className="footer-content-container">
          <nav className="footer-content" aria-label="Footer">
            <div className="categories-container">
              {SlimFooterLinks.map((column, index) => (
                <div className="category-container">
                  <FooterCategoryComponent type={column.type} links={column.links} hasMondayLogoTitle={index === 0} />
                </div>
              ))}
            </div>
          </nav>
        </div>

        <FooterBottomBarComponent SocialFooterLinks={SocialFooterLinks} />
      </footer>
    );
  };

  return (
    <>
      <div className="footer-desktop-wrapper">{renderSlimFooter()}</div>

      <div className="footer-mobile-wrapper">{renderShortFooter()}</div>

      <style jsx>{longFooterComponentStyles}</style>
      <style jsx>{componentStyles}</style>
    </>
  );
};

export default withAbTests(withGenericConfig(SlimFooter));

SlimFooter.defaultProps = DefaultProps;
