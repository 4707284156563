import { PureComponent, cloneElement } from "react";
import classnames from "classnames";
import { componentStyles } from "./preview-base-segment-component.scss";
import PencilIcon from "static/svg/pencil.svg";
import BinIcon from "static/svg/bin.svg";
import CopyIcon from "static/svg/copy2.svg";
import PasteIcon from "static/svg/paste2.svg"; //to replace
import { SortableElement, sortableHandle } from "react-sortable-hoc";
import DragHandleIcon from "/static/svg/icons/drag-handle.svg";
import ReactTooltip from "react-tooltip";
import { isFooterComponent } from "components/templates/dynamic-template/dynamic-template-service";

const DragHandle = sortableHandle(() => <DragHandleIcon />);
const tooltipDelay = 250;

class PreviewBaseSegmentComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasCopied: false };
  }

  editComponent = (e) => {
    const { index, setSelectedSegmentIndex } = this.props;

    setSelectedSegmentIndex(index);
    e.stopPropagation();
  };

  removeSegment = (e) => {
    const { index, onRemoveSegment } = this.props;

    onRemoveSegment(index);
    e.stopPropagation();
  };

  copySegmant = (e) => {
    const { setCopiedSegment, segment } = this.props;

    setCopiedSegment(segment);
    e.stopPropagation();

    this.setState({ hasCopied: true });
    setTimeout(() => {
      this.setState({ hasCopied: false });
    }, 2000);
  };

  pasteSegment = (e) => {
    // if not allowed to paste, do nothing
    if (this.isPasteSegmentDisabled()) return;

    const { copiedSegment, onPasteSegment, index } = this.props;

    if (!copiedSegment) return;

    onPasteSegment(index, copiedSegment);
    e.stopPropagation();
  };

  getPreviewBaseLayoutStyle = () => {
    const { segment } = this.props;
    return segment.props && segment.props.baseLayoutStyleProps;
  };

  childrenRendererWithoutBaseLayoutStyles = () => {
    const { children } = this.props;
    if (!(children && children.props && children.props.segment && children.props.segment.props)) {
      return children;
    }

    //remove baseLayoutStyleProps from baseLayout - because we add these styles to the preview in getPreviewBaseLayoutStyle()
    const newProps = {
      ...children.props,
      segment: { ...children.props.segment, props: { ...children.props.segment.props, baseLayoutStyleProps: null } }
    };

    return cloneElement(children, newProps);
  };

  isPasteSegmentDisabled = () => {
    const { copiedSegment, segment } = this.props;
    return !copiedSegment || isFooterComponent(segment?.type);
  };

  render() {
    const { selectedSegmentIndex, index } = this.props;
    const { hasCopied } = this.state;
    const isSelected = selectedSegmentIndex === index;

    const style = this.getPreviewBaseLayoutStyle();
    return (
      <div className={classnames("preview-base-segment-component", { "is-selected": isSelected })} style={style} onClick={this.editComponent}>
        <div className="preview-overlay">
          <div className="drag-icon-wrapper icon-wrapper" data-for={`tooltip_drag_${index}`} data-tip="Reorder">
            <ReactTooltip
              multiline={false}
              className="icon-wrapper-tooltip"
              id={`tooltip_drag_${index}`}
              effect="solid"
              place="bottom"
              delayShow={tooltipDelay}
            />
            <DragHandle />
          </div>

          <div
            className="edit-icon-wrapper icon-wrapper"
            data-for={`tooltip_edit_${index}`}
            data-tip="Edit"
            onClick={this.editComponent}
          >
            <ReactTooltip
              multiline={false}
              className="icon-wrapper-tooltip"
              id={`tooltip_edit_${index}`}
              effect="solid"
              place="bottom"
              delayShow={tooltipDelay}
            />
            <PencilIcon />
          </div>

          <div
            className="remove-icon-wrapper icon-wrapper"
            data-for={`tooltip_remove_${index}`}
            data-tip="Remove"
            onClick={this.removeSegment}
          >
            <ReactTooltip
              multiline={false}
              className="icon-wrapper-tooltip"
              id={`tooltip_remove_${index}`}
              effect="solid"
              place="bottom"
              delayShow={tooltipDelay}
            />
            <BinIcon />
          </div>

          <div
            className="copy-icon-wrapper icon-wrapper"
            data-for={`tooltip_copy_${index}`}
            data-tip="Copy component"
            onClick={this.copySegmant}
          >
            {!hasCopied && (
              <ReactTooltip
                multiline={false}
                className="icon-wrapper-tooltip"
                id={`tooltip_copy_${index}`}
                effect="solid"
                place="bottom"
                delayShow={tooltipDelay}
              />
            )}
            <CopyIcon />
            {hasCopied && <span className="has-copied">Copied!</span>}
          </div>

          <div
            className={classnames("paste-icon-wrapper", "icon-wrapper", {
              disabled: this.isPasteSegmentDisabled()
            })}
            onClick={this.pasteSegment}
            data-for={`tooltip_paste_${index}`}
            data-tip="Paste component below"
          >
            <ReactTooltip
              multiline={false}
              className="icon-wrapper-tooltip"
              id={`tooltip_paste_${index}`}
              effect="solid"
              place="bottom"
              delayShow={tooltipDelay}
            />
            <PasteIcon />
          </div>
        </div>

        {this.childrenRendererWithoutBaseLayoutStyles()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default SortableElement((props) => <PreviewBaseSegmentComponent {...props} />);
