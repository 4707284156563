import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  BaseFooter,
  RemoteWorkFooter,
  WelcomeNewJoinersFooter,
  LongFooter,
  SlimFooter
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  BaseFooterComponent: {
    type: "BaseFooterComponent",
    title: "Base",
    imgSrc: "/static/img/segments/layouts/long-footer.png",
    config: BaseFooter.BaseFooterConfig,
    advancedConfig: BaseFooter.BaseFooterAdvancedConfig,
    component: BaseFooter.BaseFooterComponent
  },
  RemoteWorkFooterComponent: {
    type: "RemoteWorkFooterComponent",
    title: "Remote Work",
    imgSrc: "/static/img/segments/layouts/remote-work-footer.png",
    config: RemoteWorkFooter.RemoteWorkFooterConfig,
    advancedConfig: RemoteWorkFooter.RemoteWorkFooterAdvancedConfig,
    component: RemoteWorkFooter.RemoteWorkFooterComponent
  },
  WelcomeNewJoinersFooterComponent: {
    type: "WelcomeNewJoinersFooterComponent",
    title: "Welcome New Joiners",
    imgSrc: "/static/img/segments/layouts/welcome-new-joiners-footer.png",
    config: WelcomeNewJoinersFooter.WelcomeNewJoinersFooterConfig,
    advancedConfig: WelcomeNewJoinersFooter.WelcomeNewJoinersFooterAdvancedConfig,
    component: WelcomeNewJoinersFooter.WelcomeNewJoinersFooterComponent
  },
  LongFooterComponent: {
    type: "LongFooterComponent",
    title: "Long (OLD)",
    imgSrc: "/static/img/segments/layouts/long-footer.png",
    config: LongFooter.LongFooterConfig,
    advancedConfig: LongFooter.LongFooterAdvancedConfig,
    component: LongFooter.LongFooterComponent
  },
  SlimFooterComponent: {
    type: "SlimFooterComponent",
    title: "Slim (OLD)",
    imgSrc: "/static/img/segments/layouts/slim-footer.png",
    config: SlimFooter.SlimFooterConfig,
    advancedConfig: SlimFooter.SlimFooterAdvancedConfig,
    component: SlimFooter.SlimFooterComponent
  }
};
