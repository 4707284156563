import { FIELD_TYPES } from "constants/template-field-type";

export const buttonLink = {
  type: FIELD_TYPES.NESTED,
  label: "Button Link",
  fields: {
    color: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Color"
    },
    textColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Text Color"
    },
    fontSize: {
      type: FIELD_TYPES.TEXT,
      label: "Font Size"
    },
    text: {
      type: FIELD_TYPES.TEXT,
      label: "Text"
    },
    shouldForwardUrlParams: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Should forward url params"
    },
    href: {
      type: FIELD_TYPES.TEXT,
      label: "URL"
    },
    placement: {
      type: FIELD_TYPES.TEXT,
      label: "BigBrain Placement"
    }
  }
};
