import css from "styled-jsx/css";
import colors from "styles/colors";
import DevelopersBannerConstants from "segments/desktop/header/layouts/developers-center-header/developers-banner/constants";

const BANNER_HEIGHT = `${DevelopersBannerConstants.DEVELOPERS_BANNER_HEIGHT}px`;

export const componentStyles = css`
  .developers-banner-component {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors["dodger-blue"]};
    min-height: ${BANNER_HEIGHT};
    width: 100vw;

    .banner-text {
      color: white;
      font-size: 1rem;
    }

    .banner-button-wrapper {
      margin-left: 24px;
      min-width: 190px;

      .banner-button {
        padding: 12px 20px;
      }

      .banner-button-text {
        font-weight: 400;
      }

      .banner-button-arrow-wrapper {
        padding-left: 8px;
        svg {
          height: 10px;
        }
      }
    }
  }
`;
