import { FIELD_TYPES } from "constants/template-field-type";
import { buttonColors } from "segments/button-service";
import { SM, MD, LG } from "components/core/new-button/button-component";
import additionalTextConfig from "segments/desktop/core-components/button/additional-text-config";

export default {
  buttonSize: {
    type: FIELD_TYPES.COMBO,
    label: "Size",
    options: [
      { value: SM, text: "Small" },
      { value: MD, text: "Medium" },
      { value: LG, text: "Large" }
    ]
  },
  buttonText: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true },
  url: { type: FIELD_TYPES.TEXT, label: "Url" },
  isOpenLinkInNewTab: { type: FIELD_TYPES.BOOLEAN, label: "Open link in new tab" },
  color: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Color",
    possibleColors: buttonColors
  },
  outline: { type: FIELD_TYPES.BOOLEAN, label: "White button with color outline" },
  ...additionalTextConfig
};
