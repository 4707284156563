import { PureComponent } from "react";
import { componentStyles } from "./plain-banner-component.scss";
import Banner from "segments/desktop/core-components/banner/banner";
class PlainBannerComponent extends PureComponent {
  render() {
    return (
      <div className="plain-banner-component">
        <Banner {...this.props} showImages={false} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

PlainBannerComponent.defaultProps = Banner.defaultProps;

export default PlainBannerComponent;
