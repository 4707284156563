import { FIELD_TYPES } from "constants/template-field-type";
import BannerFieldsConfig from "segments/desktop/core-components/banner/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "With Background",
  fields: {
    ...BannerFieldsConfig,
    bannerleftImage: {
      type: FIELD_TYPES.GALLERY,
      label: "Select an Left Image"
    },
    bannerRightImage: {
      type: FIELD_TYPES.GALLERY,
      label: "Select an Right Image"
    }
  }
};
