import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  PlainBanner,
  WithBackgroundBanner,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  PlainBannerComponent: {
    type: "PlainBannerComponent",
    title: "Plain",
    imgSrc: "/static/img/segments/layouts/plain-banner.png",
    config: PlainBanner.PlainBannerConfig,
    advancedConfig: PlainBanner.PlainBannerAdvancedConfig,
    component: PlainBanner.PlainBannerComponent
  },
  WithBackgroundBannerComponent: {
    type: "WithBackgroundBannerComponent",
    title: "With Background",
    imgSrc: "/static/img/segments/layouts/with-background-banner.png",
    config: WithBackgroundBanner.WithBackgroundBannerConfig,
    advancedConfig: WithBackgroundBanner.WithBackgroundBannerAdvancedConfig,
    component: WithBackgroundBanner.WithBackgroundBannerComponent
  },
};