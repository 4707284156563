import { FIELD_TYPES } from "constants/template-field-type";

export const showSegment = {
  type: FIELD_TYPES.COMBO,
  label: "Show Segment",
  options: [
    { value: "all", text: "All" },
    { value: "desktop", text: "Desktop Only" },
    { value: "mobile", text: "Mobile Only" }
  ]
};
