import css from "styled-jsx/css";

export const componentStyles = css`
  .dynamic-header-desktop-component {
    .dynamic-header-links {
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      align-items: center;

      .left-side-header-links,
      .right-side-header-links {
        display: flex;
      }
    }
  }
`;
