const LongFooterComponent = require("./long-footer-component").default;

import LongFooterConfig from "./long-footer-config";
import LongFooterAdvancedConfig from "./long-footer-advanced-config";

export default {
  LongFooterComponent,
  LongFooterConfig,
  LongFooterAdvancedConfig
};
