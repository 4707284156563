export const exitPopupConfig = {
  type: 'nested',
  label: 'Exit Popup',
  fields: {
    title: {
      type: 'text',
      label: 'Title'
    },
    subtitle: {
      type: 'text',
      label: 'Subtitle'
    },
    signupConfig: {
      type: 'nested',
      label: 'Signup Config',
      fields: {
        headerText: {
          type: 'text',
          label: 'Header Text'
        },
        buttonText: {
          type: 'text',
          label: 'Button Text'
        },
        inputPlaceholder: {
          type: 'text',
          label: 'Input Placeholder'
        },
        footerText: {
          type: 'text',
          label: 'Footer Text'
        }
      }
    }
  }
};
