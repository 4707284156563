import { PureComponent } from "react";
import { componentStyles } from "./developers-center-header-component.scss";
import DynamicHeaderComponent from "segments/desktop/header/layouts/dynamic-header/dynamic-header-component";
import { MONDAY_DEVELOPERS_LOGO_SRC, MONDAY_DEVELOPERS_LINK, DEVELOPER_APPS_HEADER_LINKS } from "constants/links";
import DevelopersBannerComponent from "segments/desktop/header/layouts/developers-center-header/developers-banner/developers-banner-component";

const DEVELOPERS_LOGO = {
  src: MONDAY_DEVELOPERS_LOGO_SRC,
  href: MONDAY_DEVELOPERS_LINK,
  style: { width: "290px", height: "auto" }
};

export default class DevelopersCenterHeaderComponent extends PureComponent {
  getDynamicHeaderProps() {
    const logoImage = DEVELOPERS_LOGO;
    const linkItems = DEVELOPER_APPS_HEADER_LINKS;

    return { logoImage, linkItems };
  }

  render() {
    const dynamicHeaderProps = this.getDynamicHeaderProps();

    return (
      <div className="developers-center-header-component">
        <DynamicHeaderComponent {...dynamicHeaderProps} />
        <DevelopersBannerComponent />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

DevelopersCenterHeaderComponent.defaultProps = {};
