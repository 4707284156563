import dynamic from "next/dynamic";

const RegularHeaderComponent = require("./regular-header-component").default;

import RegularHeaderConfig from "./regular-header-config";
import RegularHeaderAdvancedConfig from "./regular-header-advanced-config";

export default {
  RegularHeaderComponent,
  RegularHeaderConfig,
  RegularHeaderAdvancedConfig
};
