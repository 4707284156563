import { PureComponent } from "react";
import { componentStyles } from "./dynamic-mobile-header-menu-component.scss";
import LinkComponent from "segments/desktop/header/layouts/base-header/base-header-mobile/header-menu/link/link-component";
import DownloadMobileAppComponent from "segments/desktop/header/layouts/base-header/base-header-mobile/header-menu/download-mobile-app/download-mobile-app-component";

export default class DynamicMobileHeaderMenuComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  renderLinkItems() {
    const { linkItems } = this.props;

    return linkItems.map((linkItem, index) => {
      const { label, url } = linkItem;

      return <LinkComponent key={index} title={label} url={url} />;
    });
  }

  render() {
    return (
      <div className="dynamic-mobile-header-menu-component">
        {this.renderLinkItems()}

        <div className="download-mobile-app-component-wrapper">
          <DownloadMobileAppComponent />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

DynamicMobileHeaderMenuComponent.defaultProps = {
  linkItems: []
};
