import { createContext } from "react";
import PreviewBaseSegmentComponent from "components/templates-generator/preview-base-segment/preview-base-segment-component";

export const PreviewBaseSegmentContext = createContext(null);

export const withPreviewBaseSegment = function(WrappedComponent) {
  return props => {
    return (
      <PreviewBaseSegmentContext.Consumer>
        {value => (
          <PreviewBaseSegmentComponent {...props} {...value}>
            <WrappedComponent {...props} />
          </PreviewBaseSegmentComponent>
        )}
      </PreviewBaseSegmentContext.Consumer>
    );
  };
};
