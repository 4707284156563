import css from "styled-jsx/css";
import colors from "styles/colors";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .welcome-new-joiners-footer-component {
    min-height: 200px;
    display: flex;
    background: ${colors["gradiant-gray"]};

    .welcome-new-joiners-footer-content {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      flex-grow: 1;
      margin: auto;
      display: flex;
      align-items: center;
      padding: 32px 32px;

      .monday-address-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 300px;

        .office-location-wrapper {
          display: flex;
          align-items: center;

          &.new-york-office {
            margin-top: 16px;
          }

          &.australia-office {
            margin-top: 16px;
          }
          
          &.london-office {
            margin-top: 16px;
          }

          .location-pin-icon-wrapper {
            margin: 4px 24px 0px 0px;

            .location-pin-icon {
              height: 30px;
              width: 30px;
            }
          }

          .monday-address {
            font-size: 1rem;
            line-height: 28px;
          }
        }
      }

      .social-links-wrapper {
        display: flex;
        flex-wrap: wrap;

        .new-joiners-footer-social-link-item-component-wrapper {
          flex-shrink: 0;
          padding: 5px 0px;

          &:not(:last-child) {
            margin-right: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .welcome-new-joiners-footer-component {
      .welcome-new-joiners-footer-content {
        flex-direction: column;
        align-items: flex-start;

        .monday-address-container {
          min-width: 0;

          .location-pin-icon-wrapper {
            margin-right: 16px;

            .location-pin-icon {
              height: 32px;
              width: auto;
            }
          }

          .monday-address {
            font-size: 0.875rem;
            line-height: 24px;
          }
        }

        .social-links-wrapper {
          margin-top: 32px;
        }
      }
    }
  }
`;
